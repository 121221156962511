//@flow
import * as React from 'react';
import { Heading } from '@chakra-ui/core';
import { css } from '@emotion/core';

export const FitText: React.AbstractComponent<{|
  maxFontSize?: string,
  minFontSize?: string,
  scalar?: string,
  children: React.Node,
|}> = ({
  children,
  minFontSize = '32px',
  maxFontSize = '1400px',
  scalar = '20vw',
  fontWeight = '400',
}) => {
  return (
    <Heading
      as="span"
      fontSize="200px"
      fontWeight={fontWeight}
      css={css`
        --minFontSize: ${minFontSize};
        --maxFontSize: ${maxFontSize};
        --scaler: ${scalar};
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      `}
    >
      {children}
    </Heading>
  );
};
export default FitText;
