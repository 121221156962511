//@flow
import * as React from 'react';
import { format } from 'date-fns';

import FitText from './FItText';
import { Flex } from '@chakra-ui/core';
import { useTime } from './useTime';

export const LiveClock: React.AbstractComponent<{}> = () => {
  const clock = useTime();
  return (
    <Flex alignItems="baseline">
      <FitText scalar="19vw">
        <Flex alignItems="baseline">
          {`${format(clock, 'hh:mm:')}`}
          {`${format(clock, 'ss')}`}
          &nbsp;
          <FitText
            maxFontSize="700px"
            minFontSize="20px"
            scalar="5vw"
            fontWeight="600"
          >
            {format(clock, 'a')}
          </FitText>
        </Flex>
      </FitText>
    </Flex>
  );
};
export default LiveClock;
