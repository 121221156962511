//@flow
import * as React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { LiveClock } from '../components/LiveClock';
import { ClockControls } from './ClockControls';

const ClockFace = () => {
  return <LiveClock />;
};

const ClockPage: React.AbstractComponent<{}> = () => {
  return (
    <Box
      height="100%"
      width="100%"
      style={{
        fontFeatureSettings: "'tnum' on, 'lnum' on",
      }}
    >
      <Flex height="100%" alignItems="center" justifyContent="center">
        <ClockControls />
        <ClockFace />
      </Flex>
    </Box>
  );
};

export default ClockPage;
