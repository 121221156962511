import { theme } from '@chakra-ui/core';

export default {
  ...theme,
  colors: {
    ...theme.colors,
    gray: {
      ...theme.colors.gray,
      800: '#010101',
    },
  },
  fonts: {
    heading: '"manroperegular", sans-serif',
    body: '"manroperegular", sans-serif',
    mono: '"manroperegular", monospace',
  },
};
