import * as React from 'react';
import { Box, IconButton, useColorMode, Flex, Icon } from '@chakra-ui/core';
import { motion } from 'framer-motion';

const MotionBox = motion.custom(Box);
const MotionFlex = motion.custom(Flex);

export const ClockControls = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box position="fixed" right="14px" top="14px">
      <MotionFlex whileHover={'hover'} padding="10px" alignItems="center">
        <MotionBox
          position="absolute"
          bg="gray.700"
          size="50px"
          right="10px"
          borderRadius="2px"
          overflow="hidden"
          zIndex="-1"
          initial="default"
          transition={{ ease: 'easeIn', duration: 0.2 }}
          variants={{
            default: { scale: 1, originX: 'right', originY: 'center' },
            hover: {
              scaleX: 2,
              originX: 'right',
              originY: 'center',
            },
          }}
        />
        <MotionFlex
          alignItems="center"
          justifyContent="center"
          size="40px"
          opacity={0}
          variants={{
            default: {
              opacity: 0,
            },
            hover: {
              opacity: 1,
            },
          }}
        >
          <IconButton
            aria-label="Switch color mode"
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? 'moon' : 'sun'}
            css={{
              background: 'none !important',
              color: 'white !important',
              ':focus': {
                boxShadow: 'none',
              },
            }}
          />
        </MotionFlex>
        <MotionFlex
          alignItems="center"
          justifyContent="center"
          size="50px"
          variants={{
            hover: {
              opacity: 0.4,
            },
          }}
        >
          <Icon size="16px" color="gray.300" name={'settings'} />
        </MotionFlex>
      </MotionFlex>
    </Box>
  );
};
