import * as React from 'react';
//
// useTime hook
//
const getTime = () => {
  return new Date();
};
export const useTime = (refreshCycle: number = 100) => {
  const [now, setNow] = React.useState(getTime());

  React.useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setNow]);

  return now;
};
