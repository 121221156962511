//@flow
import * as React from 'react';
import { ThemeProvider, CSSReset, ColorModeProvider } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';
import ClockPage from './pages/ClockPage';
import customTheme from './theme/theme';
import fontCSS from './fonts/manrope.css';
const App: React.AbstractComponent<{}> = () => (
  <ThemeProvider theme={customTheme}>
    <ColorModeProvider>
      <Global
        styles={css`
          ${fontCSS}
          html,
          body,
          #app {
            height: 100%;
            width: 100%;
            overflow: hidden;
            font-family: 'manroperegular', 'sans-serif';
          }
        `}
      />
      <CSSReset />
      <ClockPage />
    </ColorModeProvider>
  </ThemeProvider>
);

export default App;
